export const formatPhoneNumber = (phoneNumber) => {
    const cleanedNumber = phoneNumber.replace(/[^0-9]/g, '');

    if (cleanedNumber.startsWith('48') || cleanedNumber.length === 9) {
        const localNumber = cleanedNumber.length === 9 ? cleanedNumber : cleanedNumber.slice(2);
        return `${localNumber.slice(0, 3)} ${localNumber.slice(3, 6)} ${localNumber.slice(6)}`;
    }

    if (cleanedNumber.startsWith('49')) {
        const localNumber = cleanedNumber.slice(2);
        if (localNumber.length <= 4) {
            return localNumber;
        } else if (localNumber.length <= 7) {
            return `${localNumber.slice(0, 3)} ${localNumber.slice(3)}`;
        } else {
            return `${localNumber.slice(0, 3)} ${localNumber.slice(3, 6)} ${localNumber.slice(6)}`;
        }
    }

    if (cleanedNumber.length <= 3) {
        return cleanedNumber;
    } else if (cleanedNumber.length <= 6) {
        return `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3)}`;
    } else {
        return `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`;
    }
};