<template>
  <div class="workshop-wrapper">
    <template v-if="workshop">
    <div class="card">
      <div class="side front">
        <div class="logo">
          <img :src="workshop.logoUrl" alt="Logo">
        </div>
        <h2>{{ workshop.name }}</h2>
        <div class="info">
          <h3>O nas</h3>
          <p>{{workshop.description}}</p>
        </div>
        <div class="hours">
          <h3>Godziny otwarcia</h3>
          <div class="working-hours">
            <p v-for="(hour, index) in workshopHours" :key="index">
              <span>{{ getDayWeek(hour.day - 1) }}:</span> <span>{{ getHourOrClosed(hour) }}</span>
            </p>
<!--            <p><span>Poniedziałek:</span> <span>8:00 - 18:00</span></p>-->
<!--            <p><span>Wtorek:</span> <span>8:00 - 18:00</span></p>-->
<!--            <p><span>Środa:</span> <span>8:00 - 18:00</span></p>-->
<!--            <p><span>Czwartek:</span> <span>8:00 - 18:00</span></p>-->
<!--            <p><span>Piątek:</span> <span>8:00 - 18:00</span></p>-->
<!--            <p><span>Sobota:</span> <span>9:00 - 14:00</span></p>-->
<!--            <p><span>Niedziela:</span> <span>Zamknięte</span></p>-->
          </div>
        </div>
      </div>
      <div class="side back">
<!--        <h3>Nasze usługi</h3>-->
<!--        <div class="services">-->
<!--          <ul>-->
<!--            <li>Przeglądy okresowe</li>-->
<!--            <li>Diagnostyka komputerowa</li>-->
<!--            <li>Naprawy mechaniczne</li>-->
<!--            <li>Wymiana oleju i filtrów</li>-->
<!--            <li>Naprawa układu hamulcowego</li>-->
<!--            <li>Naprawa zawieszenia</li>-->
<!--            <li>Wymiana rozrządu</li>-->
<!--            <li>Klimatyzacja - serwis</li>-->
<!--            <li>Wymiana opon</li>-->
<!--            <li>Geometria kół</li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="info">
          <h3>Kontakt</h3>
          <p>📞 Tel: {{  workshop.dialCode }} {{ formatPhoneNumber(workshop.dialCode + '' + workshop.phoneNumber) }}</p>
          <p>📧 Email: {{  workshop.email || 'Brak' }}</p>
          <p>📍 ul. {{ workshop.location }}</p>
        </div>
      </div>
    </div>
    </template>
    <template v-else>
      <div class="not-found">
        <h2>Warsztat nie został znaleziony</h2>
        <p>Sprawdź, czy poprawnie wpisałeś adres lub spróbuj ponownie później.</p>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import {formatPhoneNumber} from "@/helper/phoneNumberFormatter";

export default {

  data() {
    return {
      workshop: {},
      workshopHours: [],
      WEEK_DAYS: ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"]
    }
  },

  methods: {
    formatPhoneNumber,
    getHourOrClosed(hour) {
      if (hour.closed) {
        return "Zamknięte"
      }

      return hour.from + " - " + hour.to
    },

    getDayWeek(index) {
      if (index < 0 || index > 6) {
        return "Indeks musi być w zakresie 0-6";
      }

      return this.WEEK_DAYS[index];
    }
  },

  async created() {
    try {
      const {data} = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/workshop/${this.$route.params.id}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })

      this.workshop = data
      this.workshopHours = JSON.parse(this.workshop.hours)
    } catch (error) {
      this.workshop = null
    }
  }
}
</script>

<style scoped>
.workshop-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #134e4a, #14b8a6);
  perspective: 1000px;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.workshop-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.not-found {
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  border-radius: 20px;
  max-width: 400px;
  margin: auto;
  z-index: 1;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.not-found h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ff6b6b;
}

.not-found p {
  font-size: 16px;
}

.card {
  position: relative;
  width: 500px;
  height: 850px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 35px 80px rgba(0,0,0,0.15);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  z-index: 1;
  margin: auto;
}

.card:hover {
  transform: rotateY(180deg);
}

.side {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 20px;
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.9);
  overflow: auto;
  box-sizing: border-box;
}

.front {
  background: rgba(255, 255, 255, 0.95);
}

.back {
  background: rgba(248, 249, 250, 0.95);
  transform: rotateY(180deg);
}

.logo {
  width: 180px;
  height: 180px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #0d9488;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h2 {
  color: #134e4a;
  text-align: center;
  margin-bottom: 15px;
  font-size: 28px;
}

.info {
  margin: 20px 0;
}

.info h3 {
  color: #134e4a;
  margin-bottom: 10px;
  font-size: 20px;
}

.info p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
  font-size: 15px;
}

.hours {
  margin: 20px 0;
}

.hours p {
  display: flex;
  justify-content: space-between;
  color: #666;
  margin: 8px 0;
  font-size: 15px;
}

.services {
  margin: 20px 0;
}

.services ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.services li {
  color: #666;
  margin: 8px 0;
  padding-left: 20px;
  position: relative;
  font-size: 15px;
}

.services li::before {
  content: "•";
  color: #0d9488;
  position: absolute;
  left: 0;
}

.working-hours {
  background: rgba(20, 184, 166, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
}

.side::-webkit-scrollbar {
  width: 8px;
}

.side::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.side::-webkit-scrollbar-thumb {
  background: #0d9488;
  border-radius: 4px;
}
</style>